import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Paulo Alves – Engenharia e Consultoria Unipessoal, Lda. ', // e.g: 'Name | Developer'
  lang: 'pt', // e.g: en, es, fr, jp
  description: 'Consultoria em Engenharia Civil', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Paulo Alves',
  subtitle: 'Consultoria em Engenharia Civil',
  cta: 'Saber mais',
  img: 'logo.png',
};

// ABOUT DATA
export const aboutData = {
  paragraphs: [
    'Paulo Alves - Engenharia e Consultoria, Lda. é uma empresa que nasce em finais de maio de 2012 motivada pela oportunidade de prestação de serviços e consultoria na área da Engenharia Civil num segmento diferenciador de mercado, destacando-se pelo elevado grau de profissionalismo, rigor técnico e qualidade de serviços.',
    'Tal como consta no pacto social, a sociedade é representada pelo Eng. º Paulo Alves, detentor de uma vasta experiência profissional com quase 30 anos ao serviço da Engenharia.',
    'A sua experiência profissional conta com um vasto curriculum nas áreas do projeto, edificação, reabilitação, conservação e restauro, planeamento, orçamentação, formação, gestão da qualidade e segurança na construção.',
    'Iniciou o seu percurso profissional em 1990, onde exerceu funções de direção de obra, direção de orçamentação e direção de produção, com destaque em obras públicas, obras de reabilitação e conservação do património arquitetónico, empreendimentos residenciais, comerciais e turísticos',
    'Participou na implementação de normas da qualidade, higiene e segurança, no âmbito da conceção, execução e comercialização de produtos imobiliários. Paulo Alves é licenciado em Engenharia Civil desde 1990 pela Universidade do Minho, com o nível de qualificação Sénior e Especialização em Direção e Gestão da Construção outorgado pela Ordem dos Engenheiros.',
    'É formador certificado.',
    'Possui Especialização em Avaliação Imobiliária e Avaliação da Propriedade Rústica com certificados emitidos pela Escola Superior de Atividades Imobiliárias (ESAI) e com registo na CMVM como perito avaliador de imóveis – PAI/2016/0385.',
    'Possui Especialização em Segurança contra Incêndios em Edifícios, com registo na ANEPC sob o n.º1456.',
  ],
};

// Features DATA
export const featuresData = [
  {
    id: nanoid(),
    img: 'features/obras.jpg',
    title: 'Gestão de Obras',
    info: [
      'Consultoria e Assessoria',
      'Fiscalização de Obras',
      'Gestão Contratual de Empreitadas',
      'Planeamento e Controlo de Obras',
      'Orçamentação',
      'Coordenação de Segurança e Saúde',
    ],
  },
  {
    id: nanoid(),
    img: 'features/projetos.jpg',
    title: 'Gestão de Projetos',
    info: [
      'Consultoria e Assessoria',
      'Estudos, acompanhamento e revisão de Projetos de Engenharia',
      'Medições e Orçamentação',
      'Elaboração de Cadernos de Encargos e/ou Processos Construtivos',
      'Elaboração de Procedimentos e Planos de Segurança e Saúde',
    ],
  },
  {
    id: nanoid(),
    img: 'features/avaliacao.jpg',
    title: 'Avaliação Imobiliária',
    info: [
      'Avaliação de Imóveis Urbanos',
      'Avaliação da Propriedade Rústica',
      'Avaliação de Imóveis para Outros Fins (fiscal, contabilidade, fundos de pensões, ...)',
      'Determinação do "justo valor" para o cálculo de indemnizações e heranças',
    ],
  },
  {
    id: nanoid(),
    img: 'features/peritagens.jpg',
    title: 'Peritagens',
    info: [
      'Consultoria e Assessoria',
      'Avaliação do Estado de Conservação de Imóveis',
      'Diagnóstico de Patologias na Construção e Análise de Causas',
      'Propostas de Intervenção, Reparação e Conservação',
      'Relatórios de Peritagens Judiciais e Extra Judiciais',
      'Determinação do "justo valor" para efeitos de expropriações',
    ],
  },
];

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Moradias',
    info:
      'Projeto, Elaboração de Caderno de Encargos, Gestão Contratual, Otimização de Processos Construtivos, Planeamento e Fiscalização de Obra, Coordenação de Segurança e Saúde',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
    photos: [
      'moradias/01.jpg',
      'moradias/02.jpg',
      'moradias/03.jpg',
      'moradias/04.jpg',
      'moradias/05.jpg',
    ],
    desc: [
      'Projeto',
      'Elaboração de Caderno de Encargos',
      'Gestão Contratual',
      'Otimização de Processos Construtivos',
      'Planeamento e Fiscalização de Obra',
      'Coordenação de Segurança e Saúde',
    ],
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Indústria',
    info: '',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
    photos: [
      'industria/01.jpg',
      'industria/02.jpg',
      'industria/03.jpg',
      'industria/04.jpg',
      'industria/05.jpg',
      'industria/06.jpg',
      'industria/07.jpg',
    ],
    desc: [
      'Acompanhamento de Obra/Projeto',
      'Planeamento e Fiscalização de Obra',
      'Apoio na Gestão Contratual',
      'Coordenação de Segurança e Saúde',
    ],
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Reabilitação e Reconstrução',
    info: '',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
    photos: [
      'reabilitacao/01.jpg',
      'reabilitacao/02.jpg',
      'reabilitacao/03.jpg',
      'reabilitacao/04.jpg',
      'reabilitacao/05.jpg',
      'reabilitacao/06.jpg',
      'reabilitacao/07.jpg',
      'reabilitacao/08.jpg',
      'reabilitacao/09.jpg',
      'reabilitacao/10.jpg',
      'reabilitacao/11.jpg',
      'reabilitacao/12.jpg',
      'reabilitacao/13.jpg',
    ],
    desc: [
      'Análise Diagnóstica',
      'Elaboração de Caderno de Encargos',
      'Otimização dos Processos Construtivos',
      'Planeamento, Fiscalização e Acompanhamento de Obra',
    ],
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Conservação e Restauro',
    info: '',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
    photos: [
      'conservacao/01.jpg',
      'conservacao/02.jpg',
      'conservacao/03.jpg',
      'conservacao/04.jpg',
      'conservacao/05.jpg',
      'conservacao/06.jpg',
      'conservacao/07.jpg',
      'conservacao/08.jpg',
      'conservacao/09.jpg',
      'conservacao/10.jpg',
      'conservacao/11.jpg',
      'conservacao/12.jpg',
      'conservacao/13.jpg',
      'conservacao/14.jpg',
      'conservacao/15.jpg',
    ],
    desc: [
      'Análise Diagnóstica',
      'Elaboração de Caderno de Encargos',
      'Planeamento, Fiscalização e Acompanhamento de Obra',
    ],
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Interessado? Entre em contacto!',
  btn: 'Enviar e-mail',
  email: 'paulo@pauloalves.pt',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'envelope',
      url: 'mailto:paulo@pauloalves.pt',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/paulo-alves-69812343/',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/PauloAlvesEngenhariaEConsultoriaUnipLda',
    },
    {
      id: nanoid(),
      name: 'phone',
      url: 'tel:+351914915922',
    },
  ],
};
